<template>
  <div>
    <UiPageHeader>
      <template #default>{{ $t('contacts') }}</template>
      <template #page-specific-content>
        <div class="tw-flex tw-flex-1 tw-justify-between tw-gap-6 tw-items-center">
          <tooltip-select tip="_contacts" class="tw-ml-2"></tooltip-select>
          <div class="tw-flex tw-items-center">
            <create-contact :refresh="refresh" v-if="checkScope(scopeLiterals.ContactsCreate)" />
            <v-menu offset-y>
              <v-btn slot="activator" color="secondary" dark :small="true" colo class="secondary">
                {{ $tc('_action', 2) }}
                <i class="material-icons"> arrow_drop_down </i>
              </v-btn>
              <v-list>
                <v-list-tile v-if="checkScope(scopeLiterals.ContactsUpdate)">
                  <v-list-tile @click="disableAll()">
                    <v-list-tile-title>Disable All</v-list-tile-title>
                  </v-list-tile>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
        </div>
      </template>
    </UiPageHeader>
    <div class="tw-py-4 tw-px-6">
      <contacts ref="contactsRef" />
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';

import UiPageHeader from '@/components/ui/UiPageHeader.vue';

import Contacts from '../../components/company/Contacts.vue';
import CreateContact from '../../components/CreateContact.vue';

export default {
  props: [],
  components: {
    Contacts: Contacts,
    CreateContact,
    UiPageHeader,
  },
  methods: {
    refresh() {
      this.$refs.contactsRef.refresh();
    },
    async disableAll() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
      };

      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!) {
              disableContacts(orgId: $orgId) {
                success
              }
            }
          `,
          // Parameters
          variables: vars,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
